.smallAnnounceCreateUpdate {
  .separator {
    margin: 40px 0 16px;
    &.close {
      margin-top: 32px;
    }
  }
  .textarea {
  }
  .text {
    &--h4 {
      margin-bottom: 32px;
    }
  }
  .toggle {
    display: flex;
    align-items: center;
    margin-top: 24px;
    .text--p3 {
      margin-left: 8px;
    }
  }
  .input,
  .dropdown,
  .textarea {
    margin: 8px 0;
  }
  .button {
    margin-top: 48px;
    & + .button {
      margin-top: 8px;
    }
  }
}
