// =============================================================================
// Colors
// =============================================================================

$black: #000000;
$white: #ffffff;
$whitish: #fcfcfc;
$reddishOrange: #f95111;
$brownishGrey: #8c7a67;
$paleBrown: #ab947d;
$veryLightBrown: #c0af9f;
$veryLightGray: #eeeceb;
$camoGreen: #463527;
$beige: #d1ccc9;
$pale: #e7e5df;

$green: #289e28;
$red: #db2f2f;
$primary: $reddishOrange;
$secondary: $brownishGrey;
// =============================================================================
// Breakpoints based on dead devices zone
// https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
// =============================================================================
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1800px;

// =============================================================================
// Fonts
// =============================================================================
$base: 16px;
