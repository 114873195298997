.toast-wrapper {
  width: 90%;
  max-width: 300px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9000;
  display: flex;
  flex-direction: column-reverse;
}
.toast {
  padding: 20px;
  background-color: $white;
  margin-top: 12px;
  border-radius: 3px;
  color: $white;
  @include box-shadow();
  font-size: 0.8rem;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s;
  &--show {
    opacity: 1;
    transform: scale(1);
  }
  &--success {
    background-color: $green;
  }
  &--error {
    background-color: $reddishOrange;
  }
}
