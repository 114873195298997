.confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  &__wrap {
    width: 90%;
    max-width: 600px;
    background-color: $white;
    border-radius: 3px;
    @include box-shadow();
  }
  &__title {
    text-align: center;
    padding: 12px 24px;
    border-bottom: 1px solid $veryLightGray;
    font-size: 20px;
    font-weight: 600;
  }
  &__message {
    padding: 24px;
    text-align: center;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 26px;
    button + button {
      margin-left: 12px;
    }
  }
}
