.layout__detail {
  .container {
    > .text {
      &--subtitle {
        margin-bottom: 10px;
      }
      &--h1 {
        margin-bottom: 25px;
      }
      &--h4 {
        margin: 40px 0 15px;
      }
      &--p3 {
        margin-bottom: 40px;
      }
    }
    .publish {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      .icon {
        width: 13px;
        height: 13px;
        margin-right: 6px;
      }
    }
    .description {
      margin: 24px 0;
    }
  }
}
