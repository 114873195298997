.trustPerson {
  .container {
    text-align: center;

    .text--h4 {
      margin-bottom: 4px;
      line-height: 1.4;
    }

    .text--subtitle {
      line-height: 1.5;
    }
  }
}
