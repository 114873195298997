.auth-guard {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    text-align: center;
    justify-content: center;

    &__container {
        max-width: 470px !important;
    }

    &__desc {
        margin-bottom: 16px;
    }

    &__logo {
        max-height: 100px;
        width: auto;
        margin-bottom: 64px;
    }
}