.error-page {
  position: fixed;
  z-index: 200;
  transform: translateX(100%);
  background-color: white;
  transition: all 0.5s;
  &--active {
    transform: translateX(0);
  }
  &__back {
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    z-index: 200;
    position: relative;
  }
}
