.listItem {
  padding: 16px 0;
  display: flex;
  position: relative;
  &__img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background: $veryLightGray;
    overflow: hidden;
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: 10%;
    flex-grow: 1;
    padding-left: 16px;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    p {
      flex-grow: 1;
    }
  }
  &__meta {
    padding: 0 20px 5px 0;
  }
  &__bottom {
    padding: 0 20px 0 0;
  }
  &__bottom {
    margin-top: 8px;
  }
  &__item {
    color: $camoGreen;
    font-size: 15px;
  }

  &--unread {
    .listItem__meta {
      p {
        font-weight: 600;
      }
    }
    .listItem__title {
      font-weight: 800;
    }
    .listItem__bottom {
      .date {
        font-weight: 600;
      }
    }
  }
  a::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &--fade {
    opacity: 0.3;
  }
}
