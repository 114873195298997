.healthAccident {
  .container {
    padding-bottom: 160px;
  }

  .text--p3 {
    margin-bottom: 12px;
  }
  .box {
    @include box-shadow;
    padding: 16px 24px;
    border-radius: 8px;
  }
  ul {
    margin-bottom: 24px;
    li {
      position: relative;
      padding-left: 22px;
      margin-bottom: 10px;
      p {
        font-size: 12px;
        color: $camoGreen;
        font-weight: 700;
      }
      span {
        font-size: 12px;
        color: $camoGreen;
      }
      &::before {
        content: "";
        display: block;
        width: 2px;
        height: 2px;
        background-color: $camoGreen;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: 0;
      }
    }
  }
  .ctaBool {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid $pale;
    padding: 24px 20px 20px;
    background-color: $whitish;
    .yesno {
      margin-top: 22px;
      display: flex;
      .button + .button {
        margin-left: 10px;
      }
    }
  }
}
