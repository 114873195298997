.breadcrumb {
  padding: 1rem 0;

  &__grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.25rem;
  }

  &__chevron {
    display: none;
  }

  &__grid-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.25rem;

    &:nth-of-type(n + 1) {
      &:not(:last-of-type) {
        .breadcrumb__chevron {
          display: block;
        }
      }
    }
  }
}
