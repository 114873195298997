.singleJob {
  .firstfield {
    margin-top: 40px;
    margin-bottom: 18px;
    .text {
      &--subtitle {
        margin-bottom: 4px;
      }
    }
  }
  .field {
    margin-bottom: 18px;
    .text {
      &--subtitle {
        margin-bottom: 4px;
      }
    }
  }
  .lastfield {
    margin-bottom: 24px;
    .text {
      &--subtitle {
        margin-bottom: 4px;
      }
    }
  }
  .button {
    margin-top: 40px;
  }
}
