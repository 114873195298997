.restaurantsMenu {
  header {
    padding: 20px;
  }
  .content {
    z-index: 10;
  }
  .weekCalendar {
    margin-bottom: 24px;
  }
}
