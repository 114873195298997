.heroSlider {
  height: 250px;
  top: -194px;
  flex-shrink: 0;
  .swiper-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .img {
    overflow: hidden;
    background-color: $brownishGrey;
    img {
      width: 100%;
    }
  }
  .swiper-button-next {
    background: rgba($black, 0.3);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $white;
    right: 20px;
    &::after {
      font-size: 12px;
      font-weight: bold;
    }
  }
  .swiper-button-prev {
    background: rgba($black, 0.3);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $white;
    left: 20px;
    &::after {
      font-size: 12px;
      font-weight: bold;
    }
  }
}
