.home {
  display: flex;
  min-height: 100vh;
  padding-bottom: 0;
  &__version {
    padding: 24px;
    margin-top: auto;
    color: $veryLightBrown;
    font-weight: 300;
    font-size: 11px;
    text-align: center;
  }
}
