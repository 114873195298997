.alert {
  padding: 10px 46px 10px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: $white;
  font-size: 11px;
  .icon {
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    margin-right: 16px;
  }
  &--primary {
    background: $primary;
  }
  &--secondary {
    background: $secondary;
  }

  &--outline {
    background: transparent;
    border: 1px solid currentColor;

    &.alert--primary {
      color: $primary;
    }
    &.alert--secondary {
      color: $secondary;
    }
  }
}
