.badgePill {
  display: block;
  padding: 3px 5px;
  font-size: 9px;
  text-transform: uppercase;
  border-radius: 50px;
  color: $white;
  letter-spacing: 0.5px;
  font-weight: 600;
  &--primary {
    background: $primary;
  }
  &--secondary {
    background: $paleBrown;
  }
}
