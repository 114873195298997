.buttonIcon {
  @include box-shadow;
  border-radius: 4px;
  padding: 16px 16px 0;
  height: 118px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.large {
    flex-direction: row;
    height: 80px;
    width: 100%;
    padding: 0 12px 0 20px;
    .text {
      margin-left: 16px;
      margin-right: 14px;
      justify-content: flex-start;
    }
  }
  .iconPos {
    position: relative;
    .badgeNotification {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .iconBack {
    width: 52px;
    height: 52px;
    border-radius: 100px;
    background-color: $veryLightGray;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 14px;
  }
  .rightArrow {
    flex-shrink: 0;
    .icon {
      height: 18px;
      width: 18px;
    }
  }
}
