.rhService {
  .separator {
    margin: 40px 0 16px;
  }
  .textarea {
    margin: 32px 0 8px;
  }
  .toggle {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .text--p3 {
      margin-left: 8px;
    }
  }
  .button {
    margin-top: 48px;
  }
  &__email-label{
    padding: 12px 2px 4px;
  }
}
