.image {
  width: 100%;
  height: 100%;
  &__tag {
    width: 100%;
    height: auto;
    display: block;
    .image--contain &,
    .image--cover & {
      height: 100%;
    }
    .image--placeholder & {
      object-position: top;
    }
    .image--cover & {
      object-fit: cover;
    }
    .image--contain & {
      object-fit: contain;
    }
  }
}
