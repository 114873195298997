.textarea {
  position: relative;
  margin: 8px 0;
  textarea {
    width: 100%;
    height: 180px;
    padding: 20px 16px;
    resize: none;
    border: 1px solid transparent;
    border-radius: 4px;
    @include box-shadow;
    transition: 300ms;
    font-size: 16px;
    color: $camoGreen;
    &:focus {
      border-color: $primary;
    }
  }
  textarea:valid ~ label {
    top: 10px;
    color: $paleBrown;
    font-size: 9px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  textarea:focus ~ label {
    top: 10px;
    color: $primary;
    font-size: 9px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  label {
    position: absolute;
    left: 16px;
    top: 20px;
    font-size: 14px;
    color: $beige;
    transition: 300ms;
  }
}
