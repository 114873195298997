.upgradeAdvice {
  .text--h2 {
    margin-bottom: 16px;
  }
  .textarea {
    margin: 32px 0 8px;
  }
  .button {
    margin-top: 24px;
  }
}
