.contact-box {
  @include box-shadow;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  &__badge {
    margin-bottom: 16px;
  }
  &__description {
    max-width: 215px;
  }
  &__subtitle {
    margin-top: 4px;
  }
  &__subtitle + &__description{
    margin-top: 8px;
  }
  &__btn {
    margin-top: 16px;
    width: 100%;
  }
}
