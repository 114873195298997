.card {
  @include box-shadow;
  border-radius: 8px;
  height: 220px;
  max-width: 246px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .img {
    width: 100%;
    height: 45%;
    background: grey;
    overflow: hidden;
    position: relative;
    .badgePill {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    img {
      width: 100%;
    }
  }
  .content {
    padding: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .text--h3 {
      flex-grow: 1;
      margin-top: 6px;
    }
  }
}
