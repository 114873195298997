.header-document {
  display: grid;
  gap: 16px;
  padding: 16px 20px;
  background: rgba(70, 53, 39, 0.04);

  .breadcrumb {
    padding: 0;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__content {
    display: grid;
    gap: 5px;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 100%;
    background: rgba(173, 145, 117, 0.2);
  }
}
