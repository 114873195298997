.announceEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px;
  > .icon {
    width: 200px;
    height: 150px;
    margin-bottom: 24px;
  }
  .text {
    &--h4 {
      margin-bottom: 8px;
      max-width: 220px;
    }
    &--p3 {
      max-width: 220px;
    }
    margin-bottom: 24px;
  }
  .button {
    max-width: 220px;
  }
}
