#homeButton {
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
  width: 60px;
  height: 60px;
  background-color: $whitish;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba($black, 0.4);
  transition: 300ms;
  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
