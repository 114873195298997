.listRecipes {
  padding-top: 24px;
  .alert {
    margin: 0 20px 30px;
  }

  li {
    & + li {
      position: relative;
      &::before {
        content: "";
        display: block;
        width: calc(100% - 50px);
        height: 1px;
        position: absolute;
        top: 0;
        left: 50px;
        background: $pale;
      }
    }
    &:first-child {
      padding-top: 0;
    }
  }
}
.restoClose {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px;
  > .icon {
    width: 188px;
    height: 135px;
    margin-bottom: 30px;
  }
  .text {
    &--h4 {
      margin-bottom: 8px;
    }
    &--p3 {
      max-width: 257px;
    }
  }
}
