.separator {
  width: calc(100% + 30px);
  height: 1px;
  background-color: $pale;
  position: relative;
  left: 0;
}

html,
body,
#root {
  height: 100%;
}
// .react-tiger-transition--route {
//   overflow-y: scroll !important;
// }
.App {
  z-index: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: $whitish;
  padding-bottom: 80px;
  overflow: hidden;
}

// .fade-enter {
//   opacity: 0;
//   z-index: 1;
// }

// .fade-enter.fade-enter-active {
//   opacity: 1;
//   transition: opacity 250ms ease-in;
// }
// $duration: 0.5s;

// .layout__page {
//   transition: transform $duration ease-in-out;
// }
// .router-view-enter {
//   position: fixed;
//   width: 100%;
//   background: white;
//   min-height: 100vh;
//   top: 0;
//   transform: translateX(100%);
//   transition: transform $duration ease-in-out;
//   z-index: 22;
// }

// .router-view-enter.router-view-enter-active {
//   transform: translateX(0%);
// }
// .router-view-back-exit {
//   position: fixed;
//   width: 100%;
//   background: white;
//   min-height: 100vh;
//   top: 0;
//   transform: translateX(0%);
//   transition: transform $duration ease-in-out;
//   z-index: 2;
// }
// .router-view-back-exit-active {
//   transform: translateX(100%);
// }

.offline {
  background-color: $primary;
  color: $white;
  text-align: center;
  font-size: 10px;
}
.iframe {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
iframe {
  width: 100%;
  flex-grow: 1;
  border: none;
}
#modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($black, 0.4);
  z-index: 50;
  display: none;
  flex-direction: column;
  align-items: center;
  &.show {
    display: flex;
  }
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-top: 160px;
    &::after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .text {
    margin-top: 20px;
  }
}
.noScroll {
  overflow: hidden !important;
}
