.badge {
    padding: 3px 4px;
    font-size: 9px;
    text-transform: uppercase;
    border-radius: 2px;
    border: 1px solid $paleBrown;
    color: $paleBrown;
    letter-spacing: 1px;
    display: inline-block;
    font-weight: 600;
    // montserrat semibold
}
