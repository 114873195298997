.tab {
  display: flex;
  position: relative;
  &__link {
    width: 40%;
    flex-grow: 1;
    background: linear-gradient($white, rgba($pale, 0.5));
    border: none;
    border-bottom: 2px solid $pale;
    color: $veryLightBrown;
    font-size: 11px;
    font-weight: 700;
    padding: 18px 0;
    &.active {
      background: $white;
      border-bottom: 2px solid $primary;
      color: $primary;
    }
    & + & {
      border-left: 1px solid $pale;
    }
  }
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    background: linear-gradient(rgba($brownishGrey, 0.07), rgba($brownishGrey, 0));
    position: absolute;
    top: 100%;
    left: 0;
  }
  &__content {
    display: none;
    &.active {
      display: block;
    }
  }
}
