.pwa-updater {
  position: fixed;
  display: flex;
  align-items: flex-end;
  bottom: 0;
  top: 0;
  z-index: 99999;
  left: 0;
  right: 0;
  background-color: rgba($black, 0.3);
  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: $primary;
    color: $white;
  }
  &__message {
    flex-grow: 1;
  }
}
