.listItemFile {
  border-bottom: 1px solid $pale;
  padding: 16px 0;
  &__level {
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-right: 8px;
    cursor: pointer;
    &,
    & svg {
      width: 36px;
      height: 36px;
    }
  }
}
