.newsBody {
  .newsText {
    margin-bottom: 32px;
    margin-top: 32px;
  }
  .newsImg {
    margin: 0 auto;
    max-width: 240px;
    width: 100%;
    height: auto;
    margin-bottom: 32px;
    background-color: $veryLightGray;
  }
  .newsCTA {
    margin-top: 40px;
  }
}
