.loading-screen {
  &--error {
    color: $red;
  }
}

.error-screen {
  small {
    font-weight: 300;
    color: $brownishGrey;
  }
  &__btn {
    margin-top: 16px;
  }
  &__info {
    font-weight: 300;
    color: $brownishGrey;
    text-align: center;
    margin-top: 50px;
    font-size: 12px;
    white-space: pre;
  }
}
