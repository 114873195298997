.page-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  @include box-shadow;
  &__wrap {
    flex-shrink: 0;
    padding: 15px 20px 10px;
    min-height: 100px;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    top: 0;
    left: 0;
    width: 100%;
  }
  &--flat {
    box-shadow: none;
  }
  &__back {
    color: $paleBrown;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 2px;
    justify-content: space-between;
    .icon {
      margin-right: 10px;
    }
    .addItem {
      .icon {
        margin: 0;
      }
    }
  }
}
