.announceDetail {
  .flexBetween {
    display: flex;
    justify-content: space-between;
    .text--p4 {
      text-transform: uppercase;
    }
  }
  .box {
    @include box-shadow;
    margin-top: 40px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      &--subtitle {
        margin-bottom: 4px;
      }
      &--h4 {
        margin-bottom: 24px;
      }
    }
    .button + .button {
      margin-top: 8px;
    }
  }
}
.updateRemove {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid $pale;
  padding: 20px;
  background-color: #fcfcfc;
  z-index: 5;
  .yesno {
    display: flex;
    .button + .button {
      margin-left: 10px;
    }
  }
}
.updateRemove ~ .container {
  padding-bottom: 140px;
}
