.healthAdvice {
  .text--h5 {
    font-weight: 400;
    margin-bottom: 32px;
    line-height: 22px;
  }

  .container {
    > .text--p3 {
      margin-top: 32px;
    }
  }
  .assurance {
    margin-top: 12px;
    .text {
      font-weight: 700;
    }
  }
}
