.list {
  overflow: hidden;
  width: 100%;

  li {
    & + li {
      position: relative;

      &::before {
        content: "";
        display: block;
        width: calc(100% + 20px);
        height: 1px;
        background-color: $pale;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}
