.icon {
  width: 26px;
  height: 26px;
  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
  &--brownishGrey {
    color: $brownishGrey;
  }
  &--paleBrown {
    color: $paleBrown;
  }
  &--white {
    color: $white;
  }
  &--orange {
    color: $primary;
  }
}
