.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: none;
  font-size: 11px;
  font-weight: 600;
  border-radius: 4px;
  color: white;
  position: relative;
  cursor: pointer;
  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
  .icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
  &--primary {
    background: $primary;
  }
  &--secondary {
    background: $secondary;
  }
  &--white {
    background: $white;
    color: $primary;
  }
  &--large {
    height: 56px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }
  &--small {
    height: 25px;
  }

  &--outline {
    background: transparent;
    border: 1px solid currentColor;

    &.button--primary {
      color: $primary;
    }
    &.button--secondary {
      color: $secondary;
    }
    &.button--white {
      color: $white;
    }
  }
}
