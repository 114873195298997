.text {
  color: $camoGreen;
  &--subtitle {
    font-size: 9px;
    letter-spacing: 1px;
    color: $paleBrown;
    text-transform: uppercase;
  }
  &--h1 {
    font-size: 27px;
    font-weight: 700;
  }
  &--h2 {
    font-size: 20px;
    font-weight: 700;
  }
  &--h3 {
    font-size: 17px;
    font-weight: 600;
  }
  &--h4 {
    font-size: 15px;
    font-weight: 600;
  }
  &--h5 {
    font-size: 14px;
    font-weight: 600;
  }
  &--p1 {
    font-size: 15px;
    font-family: "Lora";
    line-height: 24px;
  }
  &--p2 {
    font-size: 14px;
    line-height: 18px;
  }
  &--p3 {
    font-size: 12px;
    line-height: 18px;
  }
  &--p4 {
    font-size: 11px;
    color: $paleBrown;
  }
  &--link {
    font-size: 12px;
    font-weight: 600;
  }
  &--label {
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  &--orange {
    color: $reddishOrange;
  }
  &--paleBrown {
    color: $paleBrown;
  }
  &--white {
    color: $white;
  }
  &--black {
    color: $black;
  }
  &--center {
    text-align: center;
  }
  &--intro {
    font-weight: 400;
    margin-bottom: 32px;
    line-height: 22px;
    font-size: 14px;
  }
}
