.event {
  padding: 16px 20px 16px 0;
  display: flex;
  position: relative;
  &--unread {
    .title p {
      font-weight: 800;
    }
  }
  .img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background: $veryLightGray;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $camoGreen;
    span {
      display: block;
      text-align: center;
    }
    .day {
      font-size: 25px;
      font-weight: 600;
    }
    .month {
      font-size: 12px;
    }
    .hyphen {
      display: inline-block;
      width: 6px;
      height: 2px;
      background: $camoGreen;
      margin: 0 5px;
    }
    &.twoDate {
      .day {
        font-size: 17px;
      }
      .month {
        font-size: 9px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 10%;
    flex-grow: 1;
    padding-left: 16px;
    > div {
      display: flex;
    }
    .title {
      flex-grow: 1;
      align-items: flex-start;
      .text {
        flex-grow: 1;
        margin-right: 7px;
      }
    }
    .icon {
      width: 11px;
      height: 11px;
      margin-right: 6px;
    }
    .text + .icon {
      margin-left: 10px;
    }
    .bottom {
      margin-top: 5px;
    }
  }
  a::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
