.dateCalendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 52px;
    border-radius: 2px;
    border: 1px solid $veryLightBrown;
    position: relative;
    .badgeNotification {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }
    &--disabled {
        background-color: $veryLightGray;
        .text--p4 {
            color: $beige;
        }
        .text--h3 {
            color: $beige;
        }
    }
    &--actual {
        background-color: $primary;
        border-color: $primary;
        .text--p4 {
            color: $white;
        }
        .text--h3 {
            color: $white;
        }
        .badgeNotification {
            border: 1px solid $white;
            box-sizing: content-box;
        }
    }
}
