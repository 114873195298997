.container {
  padding: 32px 30px 40px;
  &--small {
    padding: 32px 20px 40px;
  }
  &--left {
    padding-right: 0;
  }
  &--y-padding-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  &--x-padding-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
