.toggleButton {
  display: block;
  position: relative;
  width: 42px;
  height: 24px;
  border-radius: 500px;
  overflow: hidden;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    left: -60000px;
  }
  .slider {
    display: block;
    height: 100%;
    background-color: $pale;
    transition: 300ms;
    &::before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-color: $white;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
      transition: 300ms;
    }
  }
  input:checked ~ .slider {
    background-color: $primary;
  }
  input:checked ~ .slider::before {
    left: 20px;
    transform: translateY(-50%);
  }
}
