.description {
    display: flex;
    flex-wrap: wrap;
    dl {
        margin: 0;
        width: 35%;
        flex-grow: 1;
    }
    dl + .secondDesc {
        margin-left: 20px;
    }
    dd {
        margin: 4px 0 16px;
    }
}
