.upload-field {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  margin-left: -8px;
  margin-right: -8px;
  &__input {
    display: flex;
    width: 67px;
    height: 67px;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid $beige;
    margin: 8px;
    &.disabled {
      opacity: 0.3;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0;
    }
  }
  &__preview {
    width: 67px;
    height: 67px;
    background-size: cover;
    position: relative;
    margin: 8px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba($black, 0.3);
  }
  &__remove {
    background-color: $reddishOrange;
    border: none;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    svg,
    .icon {
      width: 9px;
      height: 9px;
    }
  }
  &__icon {
    &,
    .icon {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      background-color: $white;
      display: flex;
      justify-content: center;
    }
    svg {
      width: 80%;
    }
  }
}
