.dropdown {
  position: relative;
  margin: 8px 0;
  .icon {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    pointer-events: none;
  }
  select {
    appearance: none;
    width: 100%;
    padding: 26px 16px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    @include box-shadow;
    transition: 300ms;
    font-size: 16px;
    color: $camoGreen;
    &:focus {
      border-color: $primary;
    }
  }
  select:valid ~ label {
    top: 10px;
    color: $paleBrown;
    font-size: 9px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  select:focus ~ label {
    color: $primary;
  }
  label {
    position: absolute;
    left: 16px;
    top: 20px;
    font-size: 14px;
    color: $beige;
    transition: 300ms;
    pointer-events: none;
  }
}
