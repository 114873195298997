.badgeNotification {
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    text-transform: uppercase;
    border-radius: 50px;
    color: $white;
    font-weight: 600;
    background-color: $primary;
    &--outline {
        border: 1px solid $white;
    }
    &--empty {
        width: 6px;
        height: 6px;
    }
    &--large {
        width: 32px;
        height: 32px;
        font-size: 17px;
    }
}
