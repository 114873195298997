.recipe {
  padding: 16px 20px 16px 0;
  display: flex;
  .vertical {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      flex-shrink: 0;
      width: 90px;
      text-transform: uppercase;
      transform: rotate(-90deg);
    }
  }
  .img {
    width: 86px;
    height: 86px;
    border-radius: 50%;
    background: $veryLightGray;
    overflow: hidden;
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position: center center;
    color: $pale;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 10%;
    flex-grow: 1;
    padding-left: 16px;
    .text--p4 {
      margin-top: 2px;
      flex-grow: 1;
    }
  }
}
