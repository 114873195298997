.wysiwyg {
  overflow: hidden;
  &--serif {
    font-family: "Lora", serif;
  }
  font-size: 15px;
  line-height: 24px;
  ol > li {
    list-style: decimal;
  }
  ul {
    color: $camoGreen;
    li {
      padding-left: 22px;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 2px;
        height: 2px;
        background-color: $camoGreen;
        border-radius: 50%;
        position: absolute;
        top: 0.5em;
        left: 0;
      }
    }
  }
}
