.healthPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &__item {
    & + & {
      margin-top: 7px;
    }
  }
  &__cta {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    > .container {
      padding-bottom: 34px;
    }
  }
}
