.hero {
  //   box-shadow: 0 8px 20px 0 rgba($white, 1);
  flex-shrink: 0;
  top: 0;
  z-index: 10;
  &--small {
    height: 56px;
    .hero__container {
      background-color: $white;
    }
  }
  &__content {
    position: relative;
    z-index: 2;
  }
  &__background {
    position: relative;
    z-index: 1;
    background-color: $veryLightGray;
    &-wrap {
      padding-bottom: ratioCalc(1125, 900);
      position: relative;
      width: 100%;
    }
    &--sticky {
      .hero__background-wrap {
        position: fixed;
        bottom: 100%;
        transform: translateY(56px);
      }
    }
  }
  &__image {
    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  &__container {
    .hero--black & {
      background-color: $black;
    }
    .hero--white & {
      background-color: $white;
    }
    height: 56px;
    padding: 0px 20px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    &.shadow {
      background: linear-gradient(rgba($black, 0.6), rgba($black, 0));
    }
    .left {
      color: $camoGreen;
      font-size: 15px;
      display: flex;
      align-items: center;
      width: 20%;
      .arrow {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        flex-shrink: 0;
        .paleBrown {
          fill: $paleBrown;
        }
        .white {
          fill: $white;
        }
        .black {
          fill: $black;
        }
      }
    }
    .center {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .right {
      width: 20%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
