.listHome {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -4px;
  &__item {
    width: 33.333%;
    padding: 4px;
  }
}
