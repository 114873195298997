.input {
  position: relative;
  margin: 8px 0;
  display: flex;
  align-items: center;
  &__input {
    width: 100%;
    padding: 26px 16px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: 300ms;
    font-size: 16px;
    color: $camoGreen;
    .input--elevation &{
      @include box-shadow;
    }
    .input--outline &{
      border: 1px solid $beige;
    }
    .input--append &{
      padding-right: 40px;
    }
    .input &:focus {
      border-color: $primary;
    }
  }
  input:valid ~ label {
    top: 10px;
    color: $paleBrown;
    font-size: 9px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  input:not([value=""]) ~ label {
    top: 10px;
    color: $paleBrown;
    font-size: 9px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  input:focus ~ label{
    top: 10px;
    color: $primary;
    font-size: 9px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
 

  label {
    position: absolute;
    left: 16px;
    top: 20px;
    font-size: 14px;
    color: $beige;
    transition: 300ms;
  }
  &__append-icon{
    position: absolute;
    right: 16px;
    width: 18px;
    height: 18px;
    pointer-events: none;
    input:focus ~ &,
    .input--error &{
      color: $primary;
    }
  }
}
// input[value=""]
